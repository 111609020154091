import { Root, Thumb } from '@radix-ui/react-switch'
import { forwardRef } from 'react'
import { classNames } from './classnames'

export type SwitchProps = {
  id?: string
  value: boolean
  onChange?: () => void
}

export const Switch = forwardRef<HTMLButtonElement, SwitchProps>(
  ({ id, value, onChange, ...props }, ref) => {
    const height = 30
    const margin = 2
    const diameter = height - margin * 2
    const borderRadius = height / 2

    return (
      <Root
        id={id}
        {...props}
        ref={ref}
        className={classNames(
          'block flex-shrink-0',
          !value ? 'bg-gray' : 'bg-blue'
        )}
        style={{
          width: 48,
          height: height,
          borderRadius: borderRadius,
          padding: margin,
        }}
        checked={value}
        onClick={onChange}
      >
        <Thumb
          className="relative block bg-white transition-all"
          style={{
            width: diameter,
            height: diameter,
            borderRadius: borderRadius - margin,
            transform: value ? 'translateX(18px)' : '',
            fontSize: '.72rem',
          }}
        />
      </Root>
    )
  }
)
